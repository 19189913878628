<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';

import customer from '../../../api/customerService';
import '@/assets/scss/custom/rtl/_plugins-rtl.scss';

export default {
  page: {
    title: 'Customers',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'));
    },
    filteredTableData() {
      return this.tableData.filter(e => {
        if (this.searchFilter) {
          return Object.keys(e).some(key => {
            return (
              String(e[key])
                .toLowerCase()
                .indexOf(this.searchFilter.toLowerCase()) > -1
            );
          });
        } else {
          return true;
        }
      }).filter(e => {
        if (this.filterStatus != null) {
          return e.status === this.filterStatus;
        } else {
          return true;
        }
      })
    }
  },
  data() {
    return {
      tableData: [],
      title: 'Customer',
      items: [
        {
          text: 'Customer',
        },
        {
          text: 'Customer',
          active: true,
        },
      ],
      rows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterIncludedFields: ['name', 'ic_no', 'road_tax_status', 'status'],
      filterStatus: null,
      searchFilter: '',
      sortBy: 'age',
      sortDesc: false,
      fields: [
        { key: 'name', label: 'Customer Name' },
        { key: 'created_at', label: 'Date of Application' },
        { key: 'ic_no', label: 'IC No.' },
        { key: 'location', label: 'Location' },
        { key: 'road_tax_status', label: 'Road Tax' },
        { key: 'status', label: 'Status' },
        { key: 'verifier', label: 'Verifier' },
        { key: 'authorizer', label: 'Authorizer' },
        { key: 'installer', label: 'Installer' },
        { key: 'lender', label: 'Lender' }
      ],
    };
  },
  methods: {
    newCustomer() {
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
      });
    },
    onFiltered(e) {
      console.log(e);
    },
    async getData(page) {
      const getData = await customer.get(page, this.perPage);
      const newData = getData.data?.filter((f) => {
        const findData = this.tableData?.find((d) => d.id === f.id);
        return findData ? false : true;
      });
      this.tableData = [...this.tableData, ...newData] || [];
      this.rows = getData?.total;
    },
    handlePageChange(page) {
      this.getData(page);
      this.currentPage = page;
    },
    handlePerPageChange(event) {
      this.perPage = event?.target?.value;
      this.getData(this.currentPage);
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card bg-primary">
          <a
            href="/customer/id"
            class="btn btn-success waves-effect waves-light"
            >Add a new customer</a
          >
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6" v-if="userData.role_id != 5">
              <div class="dt-buttons btn-group flex-wrap">
                <button
                  class="btn btn-secondary buttons-copy buttons-html5"
                  tabindex="0"
                  aria-controls="datatable-buttons"
                  type="button"
                  @click="filterStatus = null"
                >
                  <span>All</span>
                </button>
                <button
                  class="btn btn-secondary buttons-copy buttons-html5"
                  tabindex="0"
                  aria-controls="datatable-buttons"
                  type="button"
                  @click="filterStatus = 0"
                >
                  <span>New Customers</span>
                </button>
                <button
                  class="btn btn-secondary buttons-excel buttons-html5"
                  tabindex="0"
                  aria-controls="datatable-buttons"
                  type="button"
                  @click="filterStatus = 1"
                >
                  <span>In Progress</span>
                </button>
                <button
                  class="btn btn-secondary buttons-pdf buttons-html5"
                  tabindex="0"
                  aria-controls="datatable-buttons"
                  type="button"
                  @click="filterStatus = 2"
                >
                  <span>Require Install</span>
                </button>
                <div class="btn-group">
                  <button
                    class="btn btn-secondary buttons-collection dropdown-toggle buttons-colvis"
                    tabindex="0"
                    aria-controls="datatable-buttons"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="filterStatus = 4"
                  >
                    <span>Live</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="datatable-buttons_filter" class="dataTables_filter">
                <label
                  >Search:<input
                    v-model="searchFilter"
                    type="search"
                    class="form-control form-control-sm"
                    placeholder=""
                    aria-controls="datatable-buttons"
                /></label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="filteredTableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterIncludedFields"
              @filtered="onFiltered"
            >
              <template #cell(name)="{ item: user }">
                <a v-if="user.enquiry" :href="`/customer/id?id=${user.id}`"
                  class="text-primary"
                  >{{ user.name }}</a>
              </template>
              <template #cell(verifier)="{ item: verifier }">
                {{ verifier.verifier?.name }}
              </template>
              <template #cell(authorizer)="{ item: authorizer }">
                {{ authorizer.authorizer?.name }}
              </template>
              <template #cell(installer)="{ item: installer }">
                {{ installer.installer?.name }}
              </template>
              <template #cell(lender)="{ item: lender }">
                {{ lender.lender?.name }}
              </template>
              <template #cell(ic_no)="{ item: user }">
                <div v-if="user.enquiry"
                  >{{ user.enquiry.ic_no }}</div>
              </template>
              <template #cell(road_tax_status)="{ item: user }">
                <div v-if="user.enquiry">
                  <div
                    v-if="user.enquiry.road_tax_status"
                    class="badge bg-success"
                    >Active</div>
                  <div
                    v-else
                    class="badge bg-danger"
                    >Inactive</div>
                </div>
              </template>
              <template v-if="userData.role_id != 5" #cell(status)="{ item: user }">
                <span v-if="user.status == 0" class="badge bg-danger">New</span>
                <span v-else-if="user.status == 2" class="badge bg-info">Require Install</span>
                <span v-else-if="user.status == 4" class="badge bg-success">Live</span>
                <span v-else class="badge bg-warning">In Progress</span>
              </template>
              <template v-else #cell(status)="{ item: user }">
                <span v-if="user.status == 2" class="badge bg-info">Require Install</span>
                <span v-else-if="user.vehicle?.imei" class="badge bg-success">Completed</span>
                <span v-else class="badge bg-warning">In Progress</span>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-flex align-items-center gap-2">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    @change.native="handlePerPageChange"
                  ></b-form-select
                  >&nbsp; per page
                </label>
              </div>
            </div>
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    @input="handlePageChange"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
